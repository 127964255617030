$primary: #2D4356;
$primaryLight: rgba(69, 116, 210, 0.05);
$secondary: #4574D2;
$error: #D32F2F;
$tableBorder: #D7E2EC;

body {
  margin: 0;
  font-family: 'Sora', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Sora', sans-serif !important;
  font-weight: 400;
  color: $primary;
}

p, a, span, li, div, strong, th, td {
  font-family: 'Sora', sans-serif !important;
  color: $primary;
}

button {
  font-family: 'Sora', sans-serif !important;
}

a {
  text-decoration: none;
}

.zoomIconButton {
  background-color: $primary !important;

  .MuiSvgIcon-root {
    color: #fff;
  }
}

.noDataAvailableLink {
  color: $secondary;
  font-weight: 500;
  cursor: pointer;
}

.signUpExternalLink {
  text-decoration: underline;
  cursor: pointer;
}

.notistack-SnackbarContainer {
  margin-top: 42px;
}

.MuiAccordionSummary-root,
.MuiAccordionDetails-root {
  border-color: $primary !important;
}

.MuiAutocomplete-popper {
  text-transform: capitalize;
  
  .MuiAutocomplete-paper {
    .MuiAutocomplete-listbox {
      /* Scrollbar Styles */
      &::-webkit-scrollbar {
        width: 4px;
      }
    
      &::-webkit-scrollbar-thumb {
        background-color: $primary;
        border-radius: 4px;
      }
    
      &::-webkit-scrollbar-track {
        background-color: transparent;
      }
    }
  }
}

.MuiFormHelperText-filled,
.MuiFormHelperText-contained {
  height: 20px;
}

.MuiMenu-list {
  color: $primary;
}

.scanStatusTooltip {
  margin: 6px 0;
  padding-left: 20px;

  li {
    color: #fff;
  }

  strong {
    color: #fff;
  }
}

.disabledRadio {
  color: rgba(0, 0, 0, 0.26) !important;

  span {
    color: rgba(0, 0, 0, 0.26) !important;
  }
}

.projectsTable,
.tasksTable,
.topPagesTable,
.testCasesTable,
.testRunsTable {
  border-color: $tableBorder;

  .MuiTableCell-head {
    padding: 16px 0 16px 8px;
  }

  .MuiTableCell-body {
    padding: 10px 16px;
  }

  .tableCellContentWithBorder {
    border-left: 2px solid $tableBorder;
    padding: 0 0 0 8px;
  }
}

.MuiTableCell-root {
  color: $primary !important;
  font-size: 1rem !important;
}

.pagesTable,
.tasksTable,
.topPagesTable {
  border-color: $tableBorder;
}

.projectsTable,
.tasksTable,
.testCasesTable,
.testRunsTable {
  .tableCellContentWithBorder {
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 2px solid $tableBorder;
    padding: 0 0 0 8px;
  }
}

.pagesTable {
  .tableCellContentWithBorder {
    min-height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 2px solid $tableBorder;
    padding: 0 0 0 8px;
  }
}

.dropdownButtonMenu {
  .MuiMenu-list {
    width: 221px;
    padding: 0;
  }
}

.MuiLoadingButton-root.Mui-disabled {
  background-color: #4574D2 !important;

  .MuiLoadingButton-loadingIndicator {
    color: #FFF !important;
  }
}

.MuiFormControlLabel-label {
  font-size: 20px !important;
  margin-right: 2px !important;
}

.header {
  .MuiSvgIcon-root {
    color: #fff !important;
  }

  .MuiAvatar-root {
    padding: 2px 0 0 1px;
  }

  .MuiInput-root {
    color: #fff !important;

    &:before {
      border: none;
    }

    svg {
      color: #fff;
    }

    &:hover,
    &:focus,
    &:active {
      background-color: transparent !important;

      &:before {
        border: none !important;
      }
    }
  }

  .MuiSelect-select {
    div {
      color: #fff;
      background-color: transparent !important;
    }

    &:hover,
    &:focus,
    &:active {
      background-color: transparent !important;
    }
  }
}

.headerReviewModalContainer {
  .MuiDialog-paper {
    max-height: 300px;

    .dialogContentContainer {
      padding: 16px;
  
      .dialogContentTitle {
        padding: 0 32px;
      }
    }
  }
}

.projects {
  width: 100%;
  max-height: calc(100vh - 56px);
  overflow-y: scroll;
  padding: 16px 24px;

  .projectsTableContainer {
    max-height: calc(100vh - 190px);
  }

  /* Scrollbar Styles */
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $primary;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
}

.dashboard {
  .dashboardDataContainer {
    width: 100%;
    max-height: calc(100vh - 56px);
    padding: 16px 24px;
    overflow-y: auto;

    .dashboardData {
      height: 100%;
      display: flex;
      flex-direction: column;
      background-color: #fff;

      .dashboardDataInfoContainer {
        display: flex;
        justify-content: space-between;
        border-bottom: 2px solid $tableBorder;

        .dashboardDataInfo {
          padding-bottom: 16px;
  
          .dashboardDataInfoLabel {
            display: inline-block;
            min-width: 234px;
            font-weight: 500;
          }
        }

        .dashboardDataInfoShare {
          .dashboardDataInfoShareButton {
            width: 80px;
            text-transform: none;
          }
        }

        .dashboardDataInfoSnackbar {
          position: absolute;
          top: 44px !important;
          right: 0 !important;
          width: 264px;

          .dashboardDataInfoSnackbarAlert {
            width: 100%;
            padding: 2px 2px 8px 8px;

            .MuiAlert-message {
              display: flex;
              align-items: flex-start;
              padding: 0;

              .snackbarAlertText {
                margin-top: 6px;
              }

              .snackbarAlertCloseIcon {
                width: 32px;
                height: 32px;
                color: $primary;
              }
            }
          }
        }
      }

      .dashboardDataEmpty {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .dashboardDataEmptyLink {
          text-decoration: underline;
          cursor: pointer;
        }
      }

      .dashboardDataStatsContainer {
        height: 100%;
        min-height: 640px;
        max-height: calc(100% - 180px);
        overflow-y: scroll;
        padding-top: 8px;

        .dashboardDataCharts {
          height: 100%;
          display: flex;
          justify-content: space-between;
          overflow: hidden;
          margin-bottom: 16px;
  
          .pieChartContainer,
          .barChartContainer {
            flex: 1;
            width: 50%;
            height: calc(100% - 36px);
            min-height: 580px;
            cursor: pointer;
          }
        }

        .dashboardDataTopPages {
          margin-bottom: 16px;

          .topPagesTableContainer {
            box-shadow: none;

            .topPagesTable {
              .topPagesTableItem {
                cursor: pointer;
                
                &:hover {
                  background-color: rgba(0, 0, 0, 0.04);
                }
              }

              .MuiTableHead-root {
                .MuiTableRow-root {
                  .MuiTableCell-root {
                    border-bottom: 2px solid $tableBorder;
                  }
                }
              }
            }
          }
        }

        .dashboardDataAction {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;

          .dashboardDataActionText {
            max-width: 70%;
            font-weight: 500;
          }

          .dashboardDataActionButton {
            margin: 16px 0;
            text-transform: none;
          }
        }

        &::-webkit-scrollbar {
          width: 8px;
          height: 8px;
        }
      
        &::-webkit-scrollbar-thumb {
          background-color: $primary;
          border-radius: 8px;
        }
      
        &::-webkit-scrollbar-track {
          background-color: transparent;
        }
      }
    }
  }
}

.pages {
  .discoveryMainContainer {
    width: 100%;
    max-height: calc(100vh - 56px);
    overflow-y: hidden;
    padding: 16px 24px;

    .discoveryMainContainerUITestsFilter {
      text-transform: capitalize;
    }

    .pagesTableContainer {
      max-height: calc(100vh - 336px);
      box-shadow: none !important;
      overflow-y: scroll;
      margin-top: 16px;
    }

    .checkedPagesButton {
      width: 124px;
      text-transform: none;
      margin-right: 4px;
      padding: 5px 6px;
    }

    .checkedPagesDeleteButton {
      width: 124px;
      text-transform: none;
      margin: 0 4px 0 6px;
      padding: 5px 6px;
    }

    .dropdownButton {
      width: 188px;
      text-transform: none;
      border-radius: 4px 0 0 4px;
    }
  }

  .MuiTabs-indicator {
    height: 3px;
    background-color: #4574D2;
  }

  .MuiTabs-scroller {
    .Mui-selected {
      color: $primary !important;
    }

    .MuiButtonBase-root {
      color: rgba(45,67,86,0.7);
    }
  }

  .MuiTreeItem-root {
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    padding-left: 0;

    .MuiTreeItem-content {
      padding: 0 4px;
      border-radius: 0;
    }

    .MuiCollapse-root {
      padding-left: 16px;
    }
  }

  .MuiTreeItem-root:first-child {
    border-top: 1px solid rgba(224, 224, 224, 1);
  }

  .MuiTreeItem-root:last-child {
    border-bottom: none;
  }

  .MuiTreeItem-content.Mui-selected {
    background-color: transparent;
  }

  .MuiTreeItem-content.Mui-selected.Mui-focused {
    background-color: transparent;
  }

  .MuiFormControl-root {
    .MuiFormLabel-root {
      color: $primary;
    }

    .MuiInputBase-root {
      color: $primary;

      .MuiInputAdornment-root {
        svg {
          color: $primary;
        }
      }

      .MuiAutocomplete-endAdornment {
        button {
          svg {
            color: $primary;
          }
        }
      }

      .MuiOutlinedInput-notchedOutline {
        border-color: $primary;
      }
    }

    .Mui-error {
      .MuiOutlinedInput-notchedOutline {
        border-color: $error;
      }
    }
  }

  .pageListItem {
    .MuiFormControlLabel-label {
      display: flex;
      width: 100%;
      height: 100%;
      margin-right: 0 !important;
      padding: 16px 0;
      font-size: 16px !important;
    }
  }
}

.discovery {
  .discoveryContentContainer {
    width: 100%;
    max-height: calc(100vh - 56px);
    overflow-y: scroll;
    padding: 16px 24px;

    .pageBlocksAccordionContainer,
    .pageErrorAccordionContainer {
      .accordionSummary {
        border: 1px solid $primary;
        border-radius: 8px;
        max-height: 50px;
      
        &.Mui-expanded {
          min-height: 50px;
          border-radius: 8px 8px 0 0;
        }
      }

      .accordionDetails {
        display: flex;
        padding: 0;
        border: 1px solid $primary;
        border-radius: 0 0 8px 8px;
        border-top-width: 0;

        .accordionDetailsImage {
          width: 100%;
          max-height: calc(50vh - 16px);
          // object-fit: contain;
        }

        .accordionDetailsSectionsContainer {
          height: 100%;
          display: flex;
          box-shadow: none;
          border-radius: 8px;

          .accordionDetailsSectionsContent {
            width: 100%;
            height: calc(100% - 32px);
            max-height: calc(100% - 32px) !important;
            border: 1px solid $primary;
            overflow: auto;
          
            /* Scrollbar Styles */
            &::-webkit-scrollbar {
              width: 4px;
            }
          
            &::-webkit-scrollbar-thumb {
              background-color: $primary;
              border-radius: 4px;
            }
          
            &::-webkit-scrollbar-track {
              background-color: transparent;
            }

            .accordionDetailsSectionsContentButton {
              width: 100%;
              justify-content: space-between;
              text-transform: none;

              &:hover {
                background-color: $primaryLight,
              }

              .accordionDetailsSectionsContentButtonIssues {
                width: 20px;
                height: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                line-height: 20px;
                background-color: $error;
                color: #FFF;
                margin-left: 8px;
              }
            }
          }
        }

        .accordionDetailsIssuesContainer {
          height: 100%;
          display: flex;
          box-shadow: none;
          border-radius: 8px;

          .accordionDetailsIssuesContent {
            width: 100%;
            height: calc(100% - 32px);
            max-height: calc(100% - 32px) !important;
            border: 1px solid $primary;
            overflow: auto;
          
            /* Scrollbar Styles */
            &::-webkit-scrollbar {
              width: 4px;
            }
          
            &::-webkit-scrollbar-thumb {
              background-color: $primary;
              border-radius: 4px;
            }
          
            &::-webkit-scrollbar-track {
              background-color: transparent;
            }

            .accordionDetailsIssuesContentButton {
              width: 100%;
              justify-content: space-between;
              text-transform: capitalize;

              &:hover {
                background-color: $primaryLight,
              }

              .accordionDetailsIssuesContentButtonConfidence {
                margin-left: 8px;
              }
            }

            .accordionDetailsIssuesContentText {
              width: 100%;
              height: 36.5px;
              padding: 6px 8px;
              justify-content: flex-start;
              text-transform: none;
            }
          }
        }
      }
    }

    .testCasesTableContainer {
      max-height: calc(100vh - 410px);
    }

    .MuiTabs-indicator {
      height: 3px;
      background-color: #4574D2;
    }

    .MuiTabs-scroller {
      .Mui-selected {
        color: $primary !important;
      }

      .MuiButtonBase-root {
        color: rgba(45,67,86,0.7);
      }
    }

    .MuiFormControl-root {
      .MuiFormLabel-root {
        color: $primary;
      }

      .MuiInputBase-root {
        color: $primary;

        .MuiOutlinedInput-notchedOutline {
          border-color: $primary;
        }

        .MuiSvgIcon-root {
          color: $primary;
        }
      }
    }

    /* Scrollbar Styles */
    &::-webkit-scrollbar {
      width: 8px;
    }
  
    &::-webkit-scrollbar-thumb {
      background-color: $primary;
      border-radius: 8px;
    }
  
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
  }
}

.testCase {
  .testCaseContentContainer {
    width: 100%;
    max-height: calc(100vh - 56px);
    overflow-y: scroll;
    padding: 16px;

    .testCaseDetailsTab {
      .testCaseDetailsTabTitle {
        min-height: 37px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 16px;
      }

      .testCaseDetailsTabPageUrl,
      .testCaseDetailsTabPageDescription {
        min-height: 40px;
        display: flex;
        align-items: center;
        padding-left: 16px;
      }

      .testCaseDetailsTabPageVersionsContainer {
        padding-left: 16px;
        margin: 8px 0;

        .MuiFormControl-root {
          .MuiInputBase-root,
          .MuiFormLabel-root {
            color: $primary;

            .MuiOutlinedInput-notchedOutline {
              border-color: $primary;
            }

            .MuiSvgIcon-root {
              color: $primary;
            }
          }
        }
      }

      .testCaseDetailsTabLabel {
        font-weight: 600;
      }

      .testCaseDetailsTabContent {
        display: flex;
        padding: 4px 16px 16px 16px;

        .testCaseDetailsTabContentSteps {
          width: 420px;

          .MuiList-root {
            max-width: 420px;

            .testCaseDetailsTabContentStepsItem {
              border: 1px solid $primary;
              border-radius: 4px;
              margin-bottom: 8px;
              padding: 4px 8px;
            }
          }

          .testCaseDetailsTabContentAssertion {
            border: 1px solid $primary;
            border-radius: 4px;
            margin-bottom: 4px;
            padding: 8px;
            padding-left: 12px;
          }
        }

        .testCaseDetailsTabContentVideo {
          width: calc(100% - 420px);
          margin-left: 32px;
        }

        .testCaseDetailsTabContentInfo {
          width: calc(100% - 420px);
          height: 100%;
          margin-left: 32px;
        }
      }
    }

    .testCaseRunsTab {
      .testCaseRunsTabTitle {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 16px;
        height: 36.5px;
      }

      .testCaseRunsTabPageUrl {
        display: flex;
        align-items: center;
        padding-left: 16px;
      }

      .testCaseRunsTabVersionsContainer {
        padding-left: 16px;
        margin: 8px 0;

        .MuiFormControl-root {
          .MuiInputBase-root,
          .MuiFormLabel-root {
            color: $primary;

            .MuiOutlinedInput-notchedOutline {
              border-color: $primary;
            }

            .MuiSvgIcon-root {
              color: $primary;
            }
          }
        }
      }

      .testCaseRunsTableContainer {
        max-height: calc(100vh - 296px);
      }
    }

    /* Scrollbar Styles */
    &::-webkit-scrollbar {
      width: 8px;
    }
  
    &::-webkit-scrollbar-thumb {
      background-color: $primary;
      border-radius: 8px;
    }
  
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
  }
}

.testRun {
  .testRunContentContainer {
    width: 100%;
    max-height: calc(100vh - 56px);
    overflow-y: scroll;
    padding: 16px;

    .testRunContent {
      .testRunTitle {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 16px;
      }

      .testRunPageUrl,
      .testRunPageDescription {
        display: flex;
        align-items: center;
        min-height: 40px;
      }

      .testRunPageLabel {
        font-weight: 600;
      }

      .testRunMainContent {
        display: flex;

        .testRunMainContentSteps {
          width: 420px;
          margin-top: 8px;

          .MuiList-root {
            max-width: 420px;
            min-width: 420px;

            .testRunMainContentStepItem {
              border: 1px solid $primary;
              border-radius: 4px;
              padding: 4px 8px;
              margin-bottom: 8px;
            }
          }

          .testRunAssertion {
            display: flex;
            justify-content: space-between;
            border: 1px solid $primary;
            border-radius: 4px;
            margin-bottom: 4px;
            padding: 8px;
            padding-left: 12px;
          }
        }

        .testRunMainContentTabs {
          width: 100%;
          margin-left: 16px;

          .testRunMainContentVideoContainer {
            display: flex;
            justify-content: center;

            .testRunMainContentVideo {
              width: calc(100% - 300px);
              margin-left: 32px;
            }
          }
  
          .testRunMainContentLog {
            padding-left: 16px;
          }
        }
      }
    }

    /* Scrollbar Styles */
    &::-webkit-scrollbar {
      width: 8px;
    }
  
    &::-webkit-scrollbar-thumb {
      background-color: $primary;
      border-radius: 8px;
    }
  
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
  }
}

.projectEdit {
  .MuiInputBase-root {
    .MuiAutocomplete-endAdornment {
      button {
        svg {
          color: $primary;
        }
      }
    }

    .MuiOutlinedInput-notchedOutline {
      border-color: $primary;
    }
  }
}

.profile {
  .MuiInputBase-root {
    .MuiAutocomplete-endAdornment {
      button {
        svg {
          color: $primary;
        }
      }
    }

    .MuiOutlinedInput-notchedOutline {
      border-color: $primary;
    }
  }

  .profileContent {
    padding: 16px 24px;
  }
}

.tutorial {
  .tutorialContent {
    width: 100%;
    height: calc(100vh - 56px);
    padding: 16px 32px;
    overflow-y: scroll;

    .tutorialContentLinkText {
      span {
        color: $secondary;
      }
    }

    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }
  
    &::-webkit-scrollbar-thumb {
      background-color: $primary;
      border-radius: 8px;
    }
  
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
  }
}

.projectSettings {
  width: 100%;
  padding: 16px 24px;
  height: calc(100vh - 56px);
  overflow-y: scroll;

  .projectSettingsAlert {
    .MuiAlert-icon {
      margin-right: 8px;
    }
  
    .MuiAlert-message {
      padding: 4px 0;
  
      .MuiAlertTitle-root {
        margin-bottom: 2px;
      } 
    }
  }

  .projectSettingsSwitchContainer {
    .MuiButtonBase-root {
      .MuiSwitch-thumb {
        color: lightgray;
      }
    }
    .MuiButtonBase-root.Mui-checked {
      .MuiSwitch-thumb {
        color: $primary;
      }
    }
  }

  .projectLoginSetupContentContainer {
    width: 100%;

    .projectLoginSetupCredentialsContainer {
      display: flex;
      flex-direction: column;

      .projectLoginSetupCredentialsItem {
        height: 58px;
        display: flex;
        align-items: center;
        
        .projectLoginSetupCredentialsItemLabel {
          width: 200px;
          margin: 0;
          font-size: 1.25rem;
        }

        .projectLoginSetupCredentialsItemInput {
          width: 300px;
          height: 40px;
          margin: 0;
          font-size: 1.25rem;

          .Mui-error {
            margin-top: 0;
          }
        }
      }
    }

    .projectLoginSetupAlert {
      width: 652px;
      padding: 4px 8px;
      margin-top: 16px;
      margin-bottom: 16px;
    }

    .loginPageBlocksAccordionContainer,
    .failedLoginStepsAccordionContainer {
      margin-top: 12px;

      .accordionSummary {
        border: 1px solid $primary;
        border-radius: 8px;
        max-height: 50px;
      
        &.Mui-expanded {
          min-height: 50px;
          border-radius: 8px 8px 0 0;
        }
      }

      .accordionDetails {
        display: flex;
        padding: 0;
        border: 1px solid $primary;
        border-radius: 0 0 8px 8px;
        border-top-width: 0;

        .accordionDetailsSectionsContainer {
          height: 100%;
          display: flex;
          box-shadow: none;
          border-radius: 8px;

          .accordionDetailsSectionsContent {
            width: 100%;
            height: calc(100% - 32px);
            max-height: calc(100% - 32px) !important;
            border: 1px solid $primary;
            overflow: auto;
          
            /* Scrollbar Styles */
            &::-webkit-scrollbar {
              width: 4px;
            }
          
            &::-webkit-scrollbar-thumb {
              background-color: $primary;
              border-radius: 4px;
            }
          
            &::-webkit-scrollbar-track {
              background-color: transparent;
            }

            .accordionDetailsSectionsContentButton {
              width: 100%;
              justify-content: space-between;
              text-transform: none;

              &:hover {
                background-color: $primaryLight,
              }

              .accordionDetailsSectionsContentButtonElements {
                width: 20px;
                height: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                line-height: 20px;
                background-color: $secondary;
                color: #FFF;
                margin-left: 8px;
              }
            }
          }
        }

        .accordionDetailsImage {
          width: 100%;
          max-height: calc(50vh - 16px);
        }

        .accordionDetailsElementsContainer {
          height: 100%;
          display: flex;
          box-shadow: none;
          border-radius: 8px;

          .accordionDetailsElementsContent {
            width: 100%;
            height: calc(100% - 32px);
            max-height: calc(100% - 32px) !important;
            border: 1px solid $primary;
            overflow: auto;

            .accordionDetailsElementsContentButton {
              width: 100%;
              justify-content: space-between;
              text-transform: capitalize;

              &:hover {
                background-color: $primaryLight,
              }
            }

            .accordionDetailsElementsContentText {
              width: 100%;
              height: 36.5px;
              padding: 6px 8px;
              justify-content: flex-start;
              text-transform: none;
            }
          
            /* Scrollbar Styles */
            &::-webkit-scrollbar {
              width: 4px;
            }
          
            &::-webkit-scrollbar-thumb {
              background-color: $primary;
              border-radius: 4px;
            }
          
            &::-webkit-scrollbar-track {
              background-color: transparent;
            }
          }
        }
      }
    }

    /* Scrollbar Styles */
    &::-webkit-scrollbar {
      width: 8px;
    }
  
    &::-webkit-scrollbar-thumb {
      background-color: $primary;
      border-radius: 8px;
    }
  
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
  }

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $primary;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
}

.statsSummaryBox {
  width: 170px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid $primary;
  border-radius: 4px;
  text-align: center;
  padding: 8px 4px;
  margin-right: 8px;
}

.tasks {
  flex: 1;
  padding: 16px 24px;
  overflow-y: hidden;

  .tasksTableContainer {
    max-height: calc(100vh - 192px);
    box-shadow: none;
    overflow-y: auto;
  }
}

.includedIntoScanningBadge,
.tasksMenuCollapsedBadge,
.tasksMenuExpandedBadge {
  width: 24px;
  min-width: 24px;
  height: 24px;
  min-height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  line-height: 20px;
  background-color: $secondary;
  font-size: 11px;
  color: #FFF;
  margin-left: 8px;
}

.tasksMenuCollapsedBadge {
  position: absolute;
  top: -4px;
  right: -4px;
}

// Table scroll styles
.projectsTableContainer,
.pagesTableContainer,
.tasksTableContainer,
.topPagesTableContainer,
.testCasesTableContainer,
.testCaseRunsTableContainer {
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $primary;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
}

@media (max-width: 1440px) {
  .projects {
    padding: 16px 12px;
  }

  .dashboard {
    .dashboardDataContainer {
      padding: 8px;
    }
  }

  .pages {
    .discoveryMainContainer {
      padding: 8px;

      .pagesTableContainer {
        max-height: calc(100vh - 324px);
      }

      .checkedPagesButton {
        width: 119px;
        padding: 5px 4px;
      }

      .checkedPagesDeleteButton {
        width: 110px;
        padding: 5px 4px;
      }

      .dropdownButton {
        width: 168px;
        padding: 6px;
      }
    }
  }

  .dropdownButtonMenu {
    .MuiPaper-elevation {
      width: 202px;
      margin-left: 16px;
    }

    .MuiMenu-list {
      width: 202px;
      padding: 0;
    }
  }

  .tasks {
    padding: 8px;

    .tasksTableContainer {
      max-height: calc(100vh - 182px);
      overflow-x: scroll;
    }
  }
}

@media (max-width: 1200px) {
  .dashboard {
    .dashboardDataContainer {
      .dashboardData {
        .dashboardDataStatsContainer {
          .dashboardDataCharts {
            height: calc(100vh + 56px);
            flex-direction: column;
  
            .pieChartContainer,
            .barChartContainer {
              height: 50vh;
              width: 100%;

              div {
                max-height: 50vh;
              }
            }
          }
        }
      }
    }
  }
}